import {
    Button,
    Input,
    Container, 
    Header, 
    Aside, 
    Main, 
    Footer, 
    Col, 
    Submenu, 
    Menu, 
    MenuItem, 
    MenuItemGroup, 
    Tag,
    DatePicker,
    Table,
    TableColumn,
    Pagination,
    Icon,
    Empty,
    Dialog,
	MessageBox,
	Notification,
	Alert,
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

const element = {
    install: (Vue) => {
        Vue.use(Button);
        Vue.use(Input);
        Vue.use(Container);
        Vue.use(Header);
        Vue.use(Aside);
        Vue.use(Main);
        Vue.use(Footer);
        Vue.use(Col);
        Vue.use(Submenu);
        Vue.use(Menu);
        Vue.use(MenuItem);
        Vue.use(MenuItemGroup);
        Vue.use(Tag);
        Vue.use(DatePicker);
        Vue.use(Table);
        Vue.use(TableColumn);
        Vue.use(Pagination);
        Vue.use(Icon);
        Vue.use(Empty);
        Vue.use(Dialog);
		Vue.use(Alert);
		Vue.prototype.$alert = MessageBox.alert;
		Vue.prototype.$msgbox = MessageBox;
		Vue.prototype.$confirm = MessageBox.confirm;
		Vue.prototype.$alert = MessageBox.alert;
		Vue.prototype.$prompt = MessageBox.prompt;
		Vue.prototype.$notify = Notification;
		Vue.prototype.$alert = MessageBox.alert;
    }
}
export default element;