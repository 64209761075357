<template>
	<div class="home">
		<div class="dggy">
			<img src="@/assets/img/dggy.jpg" alt="正在加载中">
		</div>
		<div class="background">
			<div class="form" v-if="isFlogin">
				<div class="title">您好，请登录</div>
				<div class="input">
					<img src="@/assets/img/account.png" alt="加载中" />
					<el-input type="text" v-model="account" placeholder="账号"></el-input>
				</div>
				<div class="input">
					<img src="@/assets/img/password.png" alt="加载中" />
					<el-input type="password" v-model="password" placeholder="密码"></el-input>
				</div>
				<div class="input_code">
					<div class="input">
						<el-input type="text" v-model="code" placeholder="请输入验证码"></el-input>
					</div>
					<!-- 验证码 -->
					<div class="code"v-if="isShow" @click="img" >
						<img :src="image" alt="加载中">
					</div>
				</div>
				<div class="login">
					<el-button type="success" @click="login()" >登录</el-button>
				</div>
			</div>
			<div class="form" v-else>
				<div class="title">首次登录请修改密码</div>
				<div class="input">
					<img src="@/assets/img/password.png" alt="加载中" />
					<el-input type="password" v-model="uploadPassword" placeholder="请输入新密码"></el-input>
				</div>
				<div class="input">
					<img src="@/assets/img/password.png" alt="加载中" />
					<el-input type="password" v-model="cUploadPassword" placeholder="请在次输入密码"></el-input>
				</div>
				<div class="login">
					<el-button type="success" @click="uploadLogin()" >确定</el-button>
				</div>
			</div>
			<div class="bottom">
				****版权所有2008-2022
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import { login, changePassword} from '@/request/api.js'
	import { Message } from 'element-ui';
	export default {
		name: "login",
		components: {},
		data() {
			return {
				account: "",
				password: "",
				code: "",
				image:'http://api.dongguanguoyao.kcshop.vip/captcha.html',
				isShow:true,
				isFlogin:true,
				uploadPassword:'',
				cUploadPassword:''
			};
		},
		mounted() {
			console.log("登录页");
		},
		methods: {
			login() {
				if(this.account == ''){
					Message.error("请输入账号")
				}else if(this.password == ''){
					Message.error("请输入密码")
				}else if(this.code == ''){
					Message.error("请输入验证码")
				}else{
					let data = {
						account: this.account,
						password: this.password,
						captcha: this.code
					}
					login(data).then(res => {
						if(res.data.code == 1){
							sessionStorage.setItem('token',res.data.data.userinfo.token);
							sessionStorage.setItem('userInfo',JSON.stringify(res.data.data.userinfo))
							if (res.data.data.userinfo.is_flogin== 1){
								this.isFlogin = false
							}else{
								this.$router.push('/userInfo')
							}
						}
						console.log(res.data.data)
					})
				}
			},
			//修改密码
			uploadLogin(){
				if(this.uploadPassword == ''){
					Message.error("请输入密码")
				}else if(this.cUploadPassword == ''){
					Message.error("请再次输入密码")
				}else if(this.uploadPassword != this.cUploadPassword){
					Message.error("两次密码不一致")
				}else{
					let data = {
						password: this.uploadPassword,
						c_password: this.cUploadPassword
					}
					changePassword(data).then(res=>{
						if(res.data.code == 1){
							Message.success("修改成功")
							this.$router.push('/userInfo')
						}
					})
				}
			},
			img(){
				this.isShow = false;
				this.$nextTick(()=>{
					this.isShow = true;
				})
			},
		},
	};
</script>
<style lang="scss" scoped>
	.home {
		height: 100%;
		width: 100%;
		position: relative;
		background: rgb(240, 237, 237);
		display: flex;
		flex-direction: column;
	}

	.dggy {
		width: 1400px;
		height: 217px;
		margin: 0 auto;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.background {
		width: 1400px;
		height: 90%;
		background: url('@/assets/img/loginbackground.jpg') no-repeat;
		background-size: cover;
		margin: 0 auto;
	}

	.background::before {
		content: '';
		display: block;
		height: 20px;
		background: #fff;
	}

	.form {
		width: 350px;
		height: 300px;
		// border: 1px solid rgb(187, 187, 187);
		border-radius: 10px;
		padding: 20px 20px;
		background: #fff;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		box-shadow: 0px 01px 0px 1px rgba(223, 240, 239);
		-webkit-box-shadow: 0px 1px 0px 1px rgba(223, 240, 239);
		-moz-box-shadow: 0px 0px 0px 1px rgba(223, 240, 239);

		.title {
			text-align: center;
			font-weight: 600;
			font-size: 20px;
			color: #008E3C
		}

		.input {
			width: 300px;
			height: 40px;
			background: #fff;
			display: flex;
			align-items: center;
			border-radius: 5px;
			margin: 20px auto 0 auto;
			border: 1px solid #B9DECA;

			img {
				width: 25px;
				height: 25px;
				margin-left: 15px;
			}
		}

		.input_code {
			margin-top: 20px;
			display: flex;
			align-items: center;
			justify-content: center;

			.input {
				width: 190px;
				margin: 0 0;
			}

			.code {
				width: 100px;
				height: 40px;
				background: #EBF8FE;
				margin-left: 10px;
				img{
					width: 100%;
					height: 100%;
				}
			}
		}

		.login {
			width: 300px;
			text-align: center;
			margin: 40px auto 0 auto;
		}
	}

	.bottom {
		position: absolute;
		left: 50%;
		bottom: 10px;
		transform: translateX(-50%);
		color: #A1A7A7;
	}

	::v-deep .el-input__inner {
		width: 100%;
		height: 30px;
		border: 0px solid #fff;
		border-radius: 5px;
		font-size: 16px;
		padding: 0 10px;
		line-height: 30px;
	}

	::v-deep .el-input__inner::-webkit-input-placeholder {
		color: #A8A8A8;
	}

	::v-deep .el-button {
		width: 130px;
		font-size: 18px;
		font-weight: 500;
		letter-spacing: 4px;
		text-indent: 4px;
	}

	::v-deep .el-button {
		width: 100%;
	}
</style>
