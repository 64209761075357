import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path:'/home',
    name:'home',
    component:() => import('@/views/home.vue'),
    children:[
      {
        path:'/inventoryDetail',
        name:'realTimeInventory',
        component: () => import('@/views/members/realTimeInventory')   //实时库存
      },
	  {
	    path:'/inventoryzDetail',
	    name:'realTimeInventoryNo',
	    component: () => import('@/views/members/realTimeInventoryNo') // 库存数量为零
	  },
      {
        path:'/monthEndInventory',
        name:'monthEndInventory',
        component: () => import('@/views/members/monthEndInventory')  //月底库存
      },
      {
        path:'/unInventoryDetail',
        name:'nonCompliance',
        component:()=>import('@/views/members/nonCompliance')  // 不合格库存
      },
      {
        path:'/salesDetail',
        name:'salesQuery',
        component:()=> import('@/views/members/salesQuery') //销售查询明细
      },
      {
        path:'/financialDetail',
        name:'financialSettlement',
        component:() => import('@/views/members/financialSettlement') //财务结算明细
      },
      {
        path:'/procurementDetail',
        name:'purchase',
        component:()=> import('@/views/members/purchase') //采购入库明细
      },
      {
        path:'/yaoshiDetail',
        name:'order',
        component:()=> import('@/views/members/order')//药事网缺货
      },{
        path:'/quanyaoDetail',
        name:'quanOrder',
        component:()=> import('@/views/members/quanOrder') //全药网缺货
      },
      {
        path:'/inventoryDetails',
        name:'inventoryDetails',
        component:()=> import('@/views/members/inventoryDetails') //库存明细菜单
      },
      {
        path:'/retailDetail',
        name:'retailDetails',
        component:()=> import('@/views/members/retailDetails') //零售明细
      },
      {
        path:'/GSPDetails',
        name:'GSPDetails',
        component:()=> import('@/views/members/GSPDetails') //GSP资料明细
      },
      {
        path:'/userInfo',
        name:'userInfo',
        component:()=> import('@/views/members/userInfo')
      },
      {
        path:'/homePage',
        name:'homePage',
        component:()=>import('@/views/members/homePage')
      },
	  //新增
	  {
	    path:'/inventorysDetail',
	    name:'inventorysDetail',
	    component:()=>import('@/views/members/inventorysDetail') //"全公司门店实时库存"
	  },
	  {
	    path:'/retailsDetail',
	    name:'retailsDetail',
	    component:()=>import('@/views/members/retailsDetail') //全公司门店零售明细
	  }
    ]
  },
  

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // 如果用户直接访问的是登录页面，直接放行
  if(to.path == '/login') return next();
  // // 从sessionstorage中获取token值
  const token = sessionStorage.getItem('token');
  // 如果没有token，强制跳转到登录页
  if(!token){
    alert("用户未登录")
    next('/login');
    // console.log("用户未登录")
  }

  next();
})

export default router
