// 导入axios
import axios from 'axios'
import {Message,Loading} from 'element-ui'


// 创建实例
const instance = axios.create({
    baseURL:'https://api.dongguanguoyao.kcshop.vip',
    // timeout:60000,
	// headers: {'Access-Control-Allow-Origin': '*'},
})
// instance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
// let loading
// 拦截器 请求拦截
let loadingInstance
instance.interceptors.request.use(config=>{
    // 获取token
    const token = sessionStorage.getItem('token');
    if(token){
        config.headers.token = token
    }
	/* loadingInstance = Loading.service({
		  lock: true,
		  text: 'Loading',
		  spinner: 'el-icon-loading',
		  background: 'rgba(0, 0, 0, 0.7)'
	}); */
    return config
},err=>{
    return Promise.reject(err)
})

// 拦截器 响应拦截
instance.interceptors.response.use(res=>{
	if(res.data.code == 0){
		return	Message.error(res.data.msg)
	}else {
		// loadingInstance.close();
	}
    return res
},err=>{
	// Message.error(err)
    return Promise.reject(err)
})

export default instance;
