import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 标签数组
    tagArr:[]
  },
  getters: {
      getTagArr:(state)=>state.tagArr,
  },
  mutations: {
    // 添加 tag
    ADD_TAGARR(state,data){
      // console.log("赋值给tagArr",state.tagArr)
      let _tagArr = [];
      _tagArr.push(data.data)
      // 标签数组去重复
      for(let i =0;i<_tagArr.length;i++){
        if(state.tagArr.indexOf(_tagArr[i]) == -1){
          state.tagArr.push(_tagArr[i])
        }
      }
    },
    // 删除
    DEL_TAGARR(state,data){
      // console.log(data.data,"要关闭的tag")
      let delTag = state.tagArr.findIndex(item=>{
        return item.path == data.data.path
      })
      state.tagArr.splice(delTag,1)
    }
  },
  actions: {
    add({commit},data){
      // console.log("触发添加tag",data)
      commit('ADD_TAGARR',data)
    },
    del({commit},data){
      commit('DEL_TAGARR',data)
    }
  },
  modules: {

  }
})
