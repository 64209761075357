// 引入request.js
import request from './request'

// 登录接口
export const login = (params)=>request.post('/api/user/login',params);

//左边菜单接口
export const isList = (params)=>request.post('/api/Output/isList',params);

//修改密码
export const changePassword = (params)=>request.post('/api/user/changePassword',params);

// 验证码
export const code = ()=> request.get('/captcha.html')

//库存列表
export const inventoryDetail = (params)=> request.post('/api/Output/inventoryDetail',params)

//库存列表不足
export const inventoryzDetail = (params)=> request.post('/api/Output/inventoryzDetail',params)

//不合格库存列表
export const unInventoryDetail = (params)=> request.post('/api/Output/unInventoryDetail',params)

//销售明细
export const salesDetail = (params)=> request.post('/api/Output/salesDetail',params)

//财务结算明细
export const financialDetail = (params)=> request.post('/api/Output/financialDetail',params)

//采购入库明细
export const procurementDetail = (params)=> request.post('/api/Output/procurementDetail',params)

//药事网缺货
export const yaoshiDetail = (params)=> request.post('/api/Output/yaoshiDetail',params)

//全药网缺货
export const quanyaoDetail = (params)=> request.post('/api/Output/quanyaoDetail',params)

//零售明细
export const retailDetail = (params)=> request.post('/api/Output/retailDetail',params)

//GSP资料明细
export const GSPdataDetail = (params)=> request.post('/api/Output/GSPdataDetail',params)

//机构信息
export const inDetail = (params)=> request.post('/api/Output/inDetail',params)

//商品信息
export const goodsList = (params)=> request.post('/api/Output/goodsList',params)

//用户下载日志
export const userLog = (params)=> request.post('/api/user/userlog',params)


//全公司门店实时库存
export const inventorysDetail = (params)=> request.post('/api/Output/inventorysDetail',params)

//全公司门店零售明细
export const retailsDetail = (params)=> request.post('/api/Output/retailsDetail',params)